"use client";
import React from "react";
import { createRoot } from "react-dom/client";

import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./components/home";

// Extend the Chakra UI theme to add custom sizes
const theme = extendTheme({
  sizes: {
    2: "8px", // Custom size for 8px
    3: "12px", // Custom size for 12px
    4: "16px", // Custom size for 16px (default)
    5: "20px", // Custom size for 20px
    6: "24px", // Custom size for 24px
    7: "28px", // Custom size for 28px
    8: "32px", // Custom size for 32px
    9: "36px",
    21: "88px",
    37: "152px",
    // Add any other sizes you need
  },
});

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route
            index
            element={
              <>
                <Home />
              </>
            }
          />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(<App />);
