import React from "react";

const importAll = (r) => {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace("./", "")] = r(item);
  });
  console.log(images);
  return images;
};

const getImages = (name) => {
  console.log(name);
  if (name === "dir1") {
    return importAll(
      require.context(
        "../../images/dir1/",
        false,
        /\.(png|jpe?g|svg|gif|webp)$/,
      ),
    );
  }

  if (name === "dir2") {
    return importAll(
      require.context(
        "../../images/dir2/",
        false,
        /\.(png|jpe?g|svg|gif|webp)$/,
      ),
    );
  }

  if (name === "dir3") {
    return importAll(
      require.context(
        "../../images/dir3/",
        false,
        /\.(png|jpe?g|svg|gif|webp)$/,
      ),
    );
  }

  if (name === "dir4") {
    return importAll(
      require.context(
        "../../images/dir4/",
        false,
        /\.(png|jpe?g|svg|gif|webp)$/,
      ),
    );
  }

  return [];
};

export default getImages;
