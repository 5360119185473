const white = "#ffffff";
const yellow = "#deb91d";
const gray = "#323437";
const lightGray = "#646669";

const lightLightGray = "#949999";
const darkGray = "#2c2e31";
const darkDarkGray = "#222125";

const black = "#000000";

export {
  white,
  yellow,
  gray,
  lightGray,
  darkGray,
  darkDarkGray,
  lightLightGray,
  black,
};
