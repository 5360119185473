import { useEffect, useMemo, useState } from "react";

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowSize(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

const useTopHeight = () => {
  const windowWidth = useWindowSize();
  return useMemo(() => {
    let top_height = 100;
    if (windowWidth < 1000) {
      top_height = windowWidth / 10;
      if (top_height < 50) {
        top_height = 50;
      }
    }
    return top_height;
  }, [windowWidth]);
};

export default useTopHeight;
