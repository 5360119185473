import {
  Box,
  Button,
  Checkbox,
  createMultiStyleConfigHelpers,
  Divider,
  Flex,
  Grid,
  GridItem,
  Icon,
  Spacer,
  Text,
  Tooltip,
  Image,
} from "@chakra-ui/react";
import { lightGray, white, lightLightGray, black } from "./functions/Colors";
import { useEffect, useState } from "react";

import { CiCircleQuestion } from "react-icons/ci";

import "./css/calculator.css";

const CustomCheckbox = ({
  text,
  price,
  setPrice,
  priceOption,
  priceMultiplier,
  setPriceMultiplier,
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setIsChecked(checked); // Update checked state

    if (checked) {
      if (typeof text[1] === "string" || text[1] instanceof String) {
        setPriceMultiplier(priceMultiplier + parseFloat(text[1]));
      } else {
        setPrice(price + text[1][priceOption]);
      }
    } else {
      if (typeof text[1] === "string" || text[1] instanceof String) {
        setPriceMultiplier(priceMultiplier - parseFloat(text[1]));
      } else {
        setPrice(price - text[1][priceOption]);
      }
    }
  };

  const formattedText = Array.isArray(text[0])
    ? text[0][0]
    : text[0].split(" ").map((word, index) => (
        <span key={index}>
          {word}
          {index === 0 && text[0].split(" ").length === 2 ? <br /> : " "}
        </span>
      ));

  return (
    <Flex>
      <Checkbox
        isChecked={isChecked} // Control the checkbox state
        onChange={handleCheckboxChange} // Handle change event
        {...props} // Pass all other props to the Checkbox
      >
        <div style={{ display: "inline-block", lineHeight: "1" }}>
          {formattedText}
        </div>
      </Checkbox>
    </Flex>
  );
};

const HoverableElement = ({
  text1,
  text2,
  text3,
  tooltipText,
  white,
  black,
  height,
}) => {
  return (
    <>
      {text1}
      <Tooltip
        label={tooltipText} // Tooltip text
        noOfLines={4}
        hasArrow={true}
        maxW={height / 1.75}
        fontFamily="LonelyCake"
        bgColor={white}
        color={black}
        fontSize={height / 30}
        lineHeight={height / 10 / 50}
        rounded={height / 30}
        placement="bottom"
      >
        <Text as="span" color={white} position="relative">
          {text2}
        </Text>
      </Tooltip>
      {text3}
    </>
  );
};

const CalculatorPage = ({ height, width }) => {
  const [price, setPrice] = useState(0);
  const [priceMultiplier, setPriceMultiplier] = useState(1);

  const [priceOption, setPriceOption] = useState(0);

  const artOptions = [
    ["Artwork", 0],
    ["Animation", 0],
    ["Reference", 0],
  ];
  const questionTree = {
    Artwork: [
      [
        "What style would you like?",
        [
          ["Flat Color", [0, 0]],
          ["Simple Shade", [0, 0]],
        ],
      ],
      [
        "How many characters will your piece contain?",
        [
          [
            ["1", 1],
            [0, 0],
          ],
          [
            ["2", 2],
            [0, 0],
          ],
          [
            ["3", 3],
            [0, 0],
          ],
          [
            ["More...", 4],
            [0, 0],
          ],
        ],
        [
          "What area of body will the character xxx show?",
          [
            ["Full body", [50, 70]],
            ["Half body", [40, 60]],
            ["Waist", [30, 50]],
          ],
        ],
        [
          "Does character xxx have any special features?",
          [
            [
              "Simple",
              [5, 10],
              "Large Antlers, Hyper genitalia, Obese body, Unusual body features...",
            ],
            [
              "Larger",
              [10, 18],
              "Wings, Naga, Hyper body, Complex body features...",
            ],
            [
              "Difficult",
              [15, 25],
              "Taur, Difficult body assets, Multiple body parts...",
            ],
            ["None", [0, 0]],
          ],
        ],
        [
          "Is character xxx one of my OC's?",
          [
            ["Yes", [0, 0]],
            ["No", "0.0"],
          ],
        ],
      ],
      [
        [
          <HoverableElement
            text1={"How many "}
            text2={"Background Characters(?)"}
            text3={" would you like to include?"}
            tooltipText={
              "Background character is a simplified, non-detailed character that adds a feel of a public crowd to a larger scene"
            }
            white={white}
            black={black}
            height={height}
          />,
          "buttons",
        ],
        [
          [
            ["1", 1],
            [0, 0],
          ],
          [
            ["2", 2],
            [0, 0],
          ],
          [
            ["3", 3],
            [0, 0],
          ],
          [
            ["None", 0],
            [0, 0],
          ],
        ],
        [
          "What area of body will the character xxx show?",
          [
            ["Full body", [50 * 0.6, 70 * 0.6]],
            ["Half body", [40 * 0.6, 60 * 0.6]],
            ["Waist", [30 * 0.6, 50 * 0.6]],
          ],
        ],
      ],
      [
        "What type of kink will your piece have?",
        [
          [
            "Common",
            "0.05",
            "Soft BDSM, Paws, Chubby/Fat, Peggy, Soft Gore, Tentacles",
          ],
          [
            "Uncommon",
            "0.1",
            "Rough BDSM, Water Sports, Diapers, Inflation, Eggnation...",
          ],
          ["Extreme", "0.5", "One to rule them all: Vore..."],
          ["None", [0, 0]],
        ],
      ],
      [
        "Will your piece have a background?",
        [
          ["Simple", [50, 70]],
          ["Difficult", [100, 140]],
          ["None", 0],
        ],
      ],
      [
        ["Any special additions?", "multi-choice"],
        [
          [
            "Close-up",
            [25, 40],
            "Bonus view for obstructed detail, like expression, penetration etc. closed up in a seperate window",
          ],
          [
            "X-ray",
            [20, 30],
            "Inside-view for a penetration, either directly on top of the scene, or as a view in a seperate window",
          ],
          ["Cum", [10, 15]],
          ["Next", [0, 0]],
        ],
      ],
      [
        [
          <HoverableElement
            text1={"Will this be a "}
            text2={"Private Commission(?)"}
            text3={""}
            tooltipText={
              "Private commissions are not shared on my public galleries, and neither on my Patreon"
            }
            white={white}
            black={black}
            height={height}
          />,
          "buttons",
        ],
        [
          ["yes", "0.3"],
          ["no", [0, 0]],
        ],
      ],
      [
        ["Select all applicable discounts you may receive.", "multi-choice"],
        [
          [
            "Returning Customer",
            "-0.15",
            "Applies once a month to any artwork after the first one",
          ],
          [
            "Patreon subscriber",
            "-0.05",
            "Applies for a 5 dollar Patreon tier membership",
          ],
          [
            "Artistic freedom",
            "-0.2",
            "I choose the scene idea with no customer input, more in my TOS",
          ],
          ["Next", [0, 0]],
        ],
      ],
    ],
    Animation: [],
    Reference: [],
  };

  const [currentArt, setCurrentArt] = useState("");

  const [buttonLayout, setButtonLayout] = useState([2, 2]);

  const [questionIndex, setQuestionIndex] = useState(-1);
  const [questionSubIndex, setQuestionSubIndex] = useState(-1);
  const [questionSavedAmount, setQuestionSavedAmount] = useState(0);

  const [showResult, setShowResult] = useState(false);

  const [OCcost, setOCcost] = useState(0);

  useEffect(() => {
    console.log(price);
  }, [price]);

  function iterateQuestion(qi, amount = 0, first = false, forward = 1) {
    if (qi <= -1 && forward === -1) {
      return false;
    }

    if (forward === -1 && qi === 0) {
      setCurrentArt("");
      setShowResult(false);

      return false;
    }

    if (qi < questionTree[currentArt].length) {
      let subQuestion = false;
      let savedAmount = questionSavedAmount;

      const subIndex = questionSubIndex + 1;

      let subQuestions = questionTree[currentArt][qi].length - 2;

      // check to see if the question can be iterated
      if (!first) {
        // check if question has sub questions
        if (questionTree[currentArt][qi].length >= 3) {
          subQuestion = true;

          // first sub index
          if (subIndex === 0) {
            savedAmount = amount;
            setQuestionSavedAmount(savedAmount);

            setOCcost(0);
          }

          if (subIndex >= subQuestions * savedAmount) {
            qi += forward;
            setQuestionIndex(qi);
            setQuestionSubIndex(-1);

            if (forward === -1) {
              setPrice(price - OCcost);
              setOCcost(0);
            }

            subQuestion = false;
          } else {
            if (forward === 1) {
              setQuestionSubIndex(subIndex);
            } else {
              qi += forward;
              setQuestionIndex(qi);
              setQuestionSubIndex(-1);

              setPrice(price - OCcost);
              setOCcost(0);

              subQuestion = false;
            }
          }
        } else {
          qi += forward;
          setQuestionIndex(qi);

          if (forward === -1) {
            setPrice(price - OCcost);
            setOCcost(0);
          }

          if (qi >= questionTree[currentArt].length) {
            setShowResult(true);
            return "uwu";
          }
        }
      }

      const tree = questionTree[currentArt][qi];

      // check question type
      if (!subQuestion) {
        if (!Array.isArray(tree[0])) {
          setCurrentQuestion(tree[0]);
          setCurrentOptions(["buttons", tree[1]]);
        } else {
          setCurrentQuestion(tree[0][0]);
          setCurrentOptions([tree[0][1], tree[1]]);
        }

        // set layout
        const answerCount = tree[1].length;
        if (answerCount === 3) {
          setButtonLayout([3, 1]);
        } else {
          setButtonLayout([2, 2]);
        }
      } else {
        const ind = Math.floor(subIndex % subQuestions) + 2;
        const numb = Math.floor(subIndex / subQuestions) + 1;

        setCurrentQuestion(
          " - " +
            String.fromCharCode(97 + ind - 2) +
            ") " +
            tree[ind][0].replace("xxx", numb),
        );
        console.log("8584058304", tree[ind][1]);
        let tmp = tree[ind][1];

        // 80% discount
        if (numb >= 2) {
          for (let i = 0; i < tmp.length; i++) {
            if (typeof tmp[i][1][0] !== "string") {
              tmp[i][1][0] = tmp[i][1][0] * 0.8;
              tmp[i][1][1] = tmp[i][1][1] * 0.8;
            }
          }
        }

        console.log("FKEOFKOWE", tmp);

        // set layout
        const answerCount = tmp.length;
        if (answerCount === 3) {
          setButtonLayout([3, 1]);
        } else {
          setButtonLayout([2, 2]);
        }

        setCurrentOptions(["buttons", tmp]);
      }
    } else {
      setShowResult(true);
    }
  }

  useEffect(() => {
    console.log(priceMultiplier);
  }, [priceMultiplier]);

  const defaultQuestion = "What art piece would you want for today?";
  const defaultOptions = ["buttons-straight", artOptions];

  useEffect(() => {
    if (currentArt !== "") {
      if (questionIndex === -1) {
        setQuestionIndex(questionIndex + 1);
        iterateQuestion(0, 0, true);
      } else {
        iterateQuestion(questionIndex, 0);
      }
    } else {
      setQuestionIndex(-1);
      setQuestionSubIndex(-1);

      setCurrentQuestion(defaultQuestion);
      setCurrentOptions(defaultOptions);

      setPrice(0);
      setPriceMultiplier(1);
    }
  }, [currentArt]);

  const [currentQuestion, setCurrentQuestion] = useState(defaultQuestion);

  const [currentOptions, setCurrentOptions] = useState(defaultOptions);

  const titleFontSize = height / 6 / 1.25;
  const questionFontSize = height / 6 / 2.75;

  const boxHeight = (0.62 * height) / 3.5;

  return (
    <Box h={height} w={width} opacity={1}>
      {/* TITLE */}
      <Flex w={width} h="15%">
        <Box h="15%" w="10%">
          <Image
            boxSize={boxHeight / 1.25}
            top={boxHeight / 7.5}
            position="absolute"
            left={boxHeight}
            src="./icons/back.png"
            cursor="pointer"
            onClick={() => {
              iterateQuestion(questionIndex, 0, false, -1);
            }}
          />
        </Box>
        <Box h="15%" w="80%">
          <Text
            fontFamily="LonelyCake"
            color={white}
            fontSize={titleFontSize}
            textAlign="center"
            noOfLines={1}
          >
            Price Calculator
          </Text>
        </Box>
        <Box h="15%" w="10%">
          <Image
            boxSize={boxHeight / 1.25}
            top={boxHeight / 7.5}
            position="absolute"
            right={boxHeight}
            src="./icons/restart.png"
            cursor="pointer"
            onClick={() => {
              setCurrentArt("");
              setShowResult(false);
            }}
          />
        </Box>
      </Flex>
      {!showResult ? (
        <>
          {/* QUESTION */}
          <Box h="15%" w="95%" position="relative" left={width / 40}>
            <Flex direction="column" h="100%">
              <Spacer />
              <Text
                fontFamily="LonelyCake"
                color={lightGray}
                fontSize={questionFontSize}
                textAlign="center"
                noOfLines={1}
                as="span"
              >
                {questionIndex + 2}) {currentQuestion}
              </Text>
            </Flex>
          </Box>
          {/* SPACER */}
          <Box h="4%" />

          {/* ANSWERS */}
          <Box h="62%" w="100%">
            {currentOptions[0] === "buttons-straight" && (
              <Flex direction="column" gap={boxHeight / 6} alignItems="center">
                {currentOptions[1].map((text) => (
                  <Box
                    as="button"
                    h={boxHeight}
                    w={boxHeight * 2.75}
                    rounded={boxHeight / 3}
                    bgColor={lightGray}
                    fontFamily="LonelyCake"
                    fontSize={questionFontSize}
                    textAlign="center"
                    textColor={"black"}
                    transition="all 0.2s"
                    _hover={{
                      transform: "scale(1.1)",
                      bgColor: lightLightGray,
                    }}
                    onClick={() => {
                      setCurrentArt(text[0]);
                      setPrice(price + text[1]);
                    }}
                  >
                    {text[0]}
                  </Box>
                ))}
              </Flex>
            )}
            {(currentOptions[0] === "buttons" ||
              currentOptions[0] === "multi-choice") && (
              <Grid
                h="100%"
                w="100%"
                templateRows={`repeat(${buttonLayout[0]}, 1fr)`}
                templateColumns={`repeat(${buttonLayout[1]}, 1fr)`}
                gap={4}
              >
                {currentOptions[1].map((text) => (
                  <GridItem
                    colSpan={1}
                    rowSpan={2 - Math.floor(currentOptions[1].length / 2.5)}
                    //bg="tomato"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {(currentOptions[0] === "buttons" ||
                      text[0] === "Next" ||
                      text[0] === "None") && (
                      <Box
                        as="button"
                        w={height / 2}
                        h={height / 5}
                        rounded={height / 20}
                        bgColor={lightGray}
                        transition="all 0.2s"
                        _hover={{
                          transform: "scale(1.1)",
                          bgColor: lightLightGray,
                        }}
                        onClick={() => {
                          iterateQuestion(questionIndex, text[0][1]);
                          if (
                            typeof text[1] === "string" ||
                            text[1] instanceof String
                          ) {
                            setPriceMultiplier(
                              priceMultiplier + parseFloat(text[1]),
                            );
                          } else {
                            if (typeof currentQuestion === "string") {
                              if (currentQuestion.includes("one of my OC's")) {
                                setPrice(price - OCcost * 0.25);
                                console.log("MINUS THIS MUCH BECAUSEO F OC");
                                console.log(OCcost);
                              } else {
                                setPrice(price + text[1][priceOption]);
                                setOCcost(OCcost + text[1][priceOption]);
                              }
                            } else {
                              setPrice(price + text[1][priceOption]);
                              setOCcost(OCcost + text[1][priceOption]);
                            }
                          }
                          if (text[0] === "Simple Shade") {
                            setPriceOption(1);
                          }
                        }}
                        //className="scale-up"
                        key={currentOptions}
                      >
                        <Text
                          fontSize={height / 12}
                          lineHeight={height / 12 / 60}
                          fontFamily="LonelyCake"
                          color={black}
                          textAlign="center"
                        >
                          {Array.isArray(text[0]) ? text[0][0] : text[0]}
                        </Text>
                      </Box>
                    )}
                    {currentOptions[0] === "multi-choice" &&
                      text[0] !== "Next" &&
                      text[0] !== "None" && (
                        <>
                          <Flex>
                            <CustomCheckbox
                              size={"lg"}
                              transform={`scale(${height / 200})`}
                              color={lightGray}
                              iconColor={black}
                              colorScheme="gray"
                              fontFamily="LonelyCake"
                              text={text}
                              price={price}
                              setPrice={setPrice}
                              priceMultiplier={priceMultiplier}
                              setPriceMultiplier={setPriceMultiplier}
                              priceOption={priceOption}
                              noOfLines={2}
                            ></CustomCheckbox>
                          </Flex>
                        </>
                      )}
                    {text.length >= 3 && (
                      <Tooltip
                        label={text[2]}
                        noOfLines={4}
                        hasArrow={true}
                        maxW={height / 1.75}
                        fontFamily="LonelyCake"
                        bgColor={white}
                        color={black}
                        fontSize={height / 25}
                        lineHeight={height / 10 / 50}
                        rounded={height / 30}
                        placement="bottom-start"
                      >
                        <Box
                          marginBottom={height / 5 / 2 / 0.5}
                          marginLeft={height / 2}
                          className="scale-up"
                          position="absolute"
                          key={currentOptions}
                        >
                          <Icon
                            as={CiCircleQuestion}
                            boxSize={height / 12}
                            color={white}
                          />
                        </Box>
                      </Tooltip>
                    )}
                  </GridItem>
                ))}
              </Grid>
            )}
          </Box>
        </>
      ) : (
        <>
          <Box h="81%" w="100%">
            <Flex direction="column" gap={boxHeight / 3} alignItems="center">
              <Spacer />
              <Box h={boxHeight / 2} />
              <Box h={boxHeight} w="100%">
                <Text
                  fontFamily="LonelyCake"
                  fontSize={questionFontSize}
                  color={white}
                  textAlign="center"
                >
                  Approximate cost for piece:{" "}
                  {Math.round(price * priceMultiplier * 100) / 100}$
                </Text>
              </Box>
              <Spacer />
              <Box
                as="button"
                h={boxHeight}
                w={boxHeight * 2.75}
                rounded={boxHeight / 3}
                bgColor={lightGray}
                fontFamily="LonelyCake"
                fontSize={questionFontSize}
                textAlign="center"
                textColor={"black"}
                transition="all 0.2s"
                _hover={{
                  transform: "scale(1.1)",
                  bgColor: lightLightGray,
                }}
                onClick={() => {
                  setCurrentArt("");
                  setShowResult(false);
                }}
              >
                Back
              </Box>
            </Flex>
          </Box>
        </>
      )}
      {/* SPACER */}
      <Box h="4%" />
    </Box>
  );
};

export default CalculatorPage;
