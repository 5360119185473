import { Box, Flex, Icon, Image, Text } from "@chakra-ui/react";
import { darkDarkGray, darkGray, lightGray } from "./functions/Colors";
import UseGetSize from "./functions/UseGetSize";

import { FaTelegram, FaDiscord, FaPatreon } from "react-icons/fa";
import { useRef, useState } from "react";

import image from "../images/icons/patreon.png";

const hexToRgb = (hex) => {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, "");

  // Parse r, g, b values
  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;

  return `${r}, ${g}, ${b}`; // Return as a string for RGBA
};

const SmallIcon = ({ icon, linkText, big = false, color = "white", h }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <Box
        position="relative"
        transition="margin 0.4s"
        marginX={isHovered ? (big ? "10px" : "10px") : "0"}
      >
        <Box
          zIndex={isHovered ? 20 : 15}
          position="absolute"
          left={-h / 10}
          top={-h / 10}
        >
          <Icon
            as={icon}
            boxSize={`${h * 2.2}px`}
            borderRadius="50%"
            border={`${Math.round(h / 2)}px solid ${lightGray}`}
            boxShadow={`inset 0px 0px 0px 100px ${lightGray}`}
            transition="transform 0.4s" // Smooth transition for hover effect
            transform={isHovered ? "scale(1.1)" : "scale(1)"}
            marginX={big ? 7 : 0}
            marginY={big ? -2 : 0}
          />
        </Box>
        <Box zIndex={20} position="relative">
          <Image
            src={`./icons/${icon}.png`}
            boxSize={`${h * 2}px`}
            border={`${h / 5}px solid ${darkDarkGray}`}
            borderRadius="50%"
            objectFit="fill"
            boxShadow={`inset 0px 0px 0px 100px ${darkDarkGray}`}
            transition="transform 0.4s" // Smooth transition for hover effect
            _hover={{ transform: "scale(1.1)" }}
            marginX={big ? 7 : 0}
            marginY={big ? -2 : 0}
            onMouseEnter={() => setIsHovered(true)} // Set hover state to true when entering
            onMouseLeave={() => setIsHovered(false)} // Set hover state to false
            bg={darkDarkGray}
            color={color}
            onClick={() => window.open("https://" + linkText)}
          />
        </Box>
      </Box>
    </>
  );
};

export const Header = () => {
  const { height, width } = UseGetSize();

  console.log(height);

  const head_height = height / 20;
  const totalHeight = head_height * 3.5;

  const color = hexToRgb(lightGray);

  return (
    <>
      <Box h={totalHeight} w={width} position="absolute">
        <Flex Flex direction="column" alignItems="center" justifyItems="center">
          <Box
            w="100%"
            h={head_height}
            bg={darkDarkGray}
            position="absolute"
            zIndex={20}
          />
          <Box
            zIndex={20}
            w="80%"
            h={head_height / 12}
            bg={`linear-gradient(to right, rgba(${color}, 0) 0%, rgba(${color}, 1) 100px, rgba(${color}, 1) calc(100% - 100px), rgba(${color}, 0) calc(100%))`}
            position="absolute"
            top={head_height} // Position it below the darkGray box
          />
          <Box h="100%">
            <Flex Flex direction="row" gap={3}>
              <Box w={head_height * 6}>
                <Flex
                  flex
                  gap={head_height / 2}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <SmallIcon icon="paw" linkText="Telegram" h={head_height} />
                  <SmallIcon icon="e61" linkText="Telegram" h={head_height} />
                </Flex>
              </Box>
              <SmallIcon
                icon="patreon"
                linkText="patreon.com"
                big={true}
                h={head_height * 1.5}
              />
              <Box w={head_height * 6}>
                <Flex
                  flex
                  gap={head_height / 2}
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <SmallIcon
                    icon="discord"
                    linkText="discord.com"
                    color="aqua"
                    h={head_height}
                  />
                  <SmallIcon
                    icon="telegram"
                    linkText="telegram.com"
                    color=""
                    h={head_height}
                  />
                </Flex>
              </Box>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </>
  );
};

export default Header;
